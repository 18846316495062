
.c-progress {
  $width: 300px;
  $radius: 5px;
  display: flex;
  align-items: center;
  span {
    margin-left: 5px;
    font-size: 14px;
    color: #666;
  }
  
  .c-progress-outer {
    width: $width;
    height: 1px;
    border-radius: $radius;
    margin-bottom: 30px;
    background: #262963;
    border: 2px solid #262963;
    position: relative;
    display: flex;
    align-items: center;
    
    .c-progress-inner {
      // width: 100px;
      height: 1px;
      background: #4B45FF;
      border: 2px solid #4B45FF;
      border-radius: 2px;
    }
    
    .c-progress-slider {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #fff;
      border: 1px solid #fff;
      position: absolute;
      z-index: 10;
      left: 10px;
    }
  }
}
